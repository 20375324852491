import axios from "../AxiosConfig";
import {
  GET_ALL_TRANSACTION_FAILURE,
  GET_ALL_TRANSACTION_REQUEST,
  GET_ALL_TRANSACTION_SUCCESS,
  POST_MANUAL_CALLBACK_FAILURE,
  POST_MANUAL_CALLBACK_REQUEST,
  POST_MANUAL_CALLBACK_SUCCESS,
} from "./actions";
import { ShowSnackbar } from "./snackbar";

const TRANSACTION_ENDPOINT = "/api/Payment/";

export const GetAllTransaction = (params: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: GET_ALL_TRANSACTION_REQUEST,
        payload: { isLoadingGetAllTransaction: true },
      });

      axios
        .get(`${TRANSACTION_ENDPOINT}GetAllPayment`, { params })
        .then((res) => {
          dispatch({
            type: GET_ALL_TRANSACTION_SUCCESS,
            payload: {
              isLoadingGetAllTransaction: false,
              transactionList: res?.data,
            },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_ALL_TRANSACTION_FAILURE,
            payload: { isLoadingGetAllTransaction: false },
          });
        });
    }
  };
};

export const ManualCallback = (data: any) => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      dispatch({
        type: POST_MANUAL_CALLBACK_REQUEST,
        payload: {
          isLoadingPostManualCallback: true,
          currentCallbackTransactionId: data?.transactionId,
        },
      });

      axios
        .post(`${TRANSACTION_ENDPOINT}ManualCallback`, data)
        .then((res) => {
          dispatch({
            type: POST_MANUAL_CALLBACK_SUCCESS,
            payload: {
              isLoadingPostManualCallback: false,
              currentCallbackTransactionId: data?.transactionId,
            },
          });

          dispatch(ShowSnackbar(`回调成功-${data?.transactionId}`, "success"));
        })
        .catch((err) => {
          dispatch({
            type: POST_MANUAL_CALLBACK_FAILURE,
            payload: {
              isLoadingPostManualCallback: false,
              currentCallbackTransactionId: data?.transactionId,
            },
          });
          dispatch(ShowSnackbar(`回调失败-${data?.transactionId}`, "error"));
        });
    }
  };
};
