import {
  GET_ALL_TRANSACTION_FAILURE,
  GET_ALL_TRANSACTION_REQUEST,
  GET_ALL_TRANSACTION_SUCCESS,
  POST_MANUAL_CALLBACK_FAILURE,
  POST_MANUAL_CALLBACK_REQUEST,
  POST_MANUAL_CALLBACK_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  transactionList: null,
  isLoadingGetAllTransaction: false,
  isLoadingPostManualCallback: false,
  currentCallbackTransactionId: null,
};

const TransactionReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
      };

    case GET_ALL_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
        transactionList: action.payload.transactionList,
      };
    case GET_ALL_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingGetAllTransaction: action.payload.isLoadingGetAllTransaction,
      };
    case POST_MANUAL_CALLBACK_REQUEST:
      return {
        ...state,
        isLoadingPostManualCallback: action.payload.isLoadingPostManualCallback,
        currentCallbackTransactionId:
          action.payload.currentCallbackTransactionId,
      };
    case POST_MANUAL_CALLBACK_SUCCESS:
      return {
        ...state,
        isLoadingPostManualCallback: action.payload.isLoadingPostManualCallback,
        currentCallbackTransactionId:
          action.payload.currentCallbackTransactionId,
      };
    case POST_MANUAL_CALLBACK_FAILURE:
      return {
        ...state,
        isLoadingPostManualCallback: action.payload.isLoadingPostManualCallback,
        currentCallbackTransactionId:
          action.payload.currentCallbackTransactionId,
      };
    default:
      return state;
  }
};

export default TransactionReducer;
