import {
  GET_ALL_DEPOSIT_RATE_FAILURE,
  GET_ALL_DEPOSIT_RATE_REQUEST,
  GET_ALL_DEPOSIT_RATE_SUCCESS,
  UPDATE_DEPOSIT_RATE_FAILURE,
  UPDATE_DEPOSIT_RATE_REQUEST,
  UPDATE_DEPOSIT_RATE_SUCCESS,
} from "../actions/actions";

const INITIAL_STATE = {
  depositRateData: null,
  isLoadingGetAllDepositRate: false,
  isLoadingUpdateDepositRate: false,
};

const RateReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_DEPOSIT_RATE_REQUEST:
      return {
        ...state,
        isLoadingGetAllDepositRate: action.payload.isLoadingGetAllDepositRate,
      };
    case GET_ALL_DEPOSIT_RATE_SUCCESS:
      return {
        ...state,
        isLoadingGetAllDepositRate: action.payload.isLoadingGetAllDepositRate,
        depositRateData: action.payload.depositRateData,
      };
    case GET_ALL_DEPOSIT_RATE_FAILURE:
      return {
        ...state,
        isLoadingGetAllDepositRate: action.payload.isLoadingGetAllDepositRate,
      };
    case UPDATE_DEPOSIT_RATE_REQUEST:
      return {
        ...state,
        isLoadingUpdateDepositRate: action.payload.isLoadingUpdateDepositRate,
      };
    case UPDATE_DEPOSIT_RATE_SUCCESS:
      return {
        ...state,
        isLoadingUpdateDepositRate: action.payload.isLoadingUpdateDepositRate,
      };
    case UPDATE_DEPOSIT_RATE_FAILURE:
      return {
        ...state,
        isLoadingUpdateDepositRate: action.payload.isLoadingUpdateDepositRate,
      };
    default:
      return state;
  }
};

export default RateReducer;
