export const USER_MAIN_ROUTE = "/user";
export const USER_ROUTE = "/user/user-list";

export const BANK_ROUTE = "/bank";
export const ACCOUNT_ROUTE = "/account";
// export const TRANSACTION_ROUTE = "/account/:id/transaction";


export const MERCHANT_ROUTE = "/user/merchant-list";
export const MERCHANT_ADMIN_ROUTE = "/user/merchant-admin-list";
export const DASHBOARD_ROUTE = "/dashboard";
export const LOGIN_ROUTE = "/login";
export const TRANSACTION_ROUTE = "/transaction-list";
export const MERCHANT_INFO_ROUTE = "/user/merchant-list/merchant-info"
