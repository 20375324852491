import {
  GET_ALL_DEPOSIT_RATE_FAILURE,
  GET_ALL_DEPOSIT_RATE_REQUEST,
  GET_ALL_DEPOSIT_RATE_SUCCESS,
  UPDATE_DEPOSIT_RATE_FAILURE,
  UPDATE_DEPOSIT_RATE_REQUEST,
  UPDATE_DEPOSIT_RATE_SUCCESS,
} from "./actions";
import axios from "../AxiosConfig";
import { ShowSnackbar } from "./snackbar";

const RATE_ENDPOINT = "/api/Setup/";
export const GetAllDepositRate = (params: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ALL_DEPOSIT_RATE_REQUEST,
      payload: { isLoadingGetAllDepositRate: true },
    });

    axios
      .get(`${RATE_ENDPOINT}GetAllDepositRate`, { params })
      .then((res) => {
        dispatch({
          type: GET_ALL_DEPOSIT_RATE_SUCCESS,
          payload: {
            isLoadingGetAllDepositRate: false,
            depositRateData: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_DEPOSIT_RATE_FAILURE,
          payload: { isLoadingGetAllDepositRate: false },
        });
      });
  };
};

export const UpdateDepositRate = (data: any, accessId: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_DEPOSIT_RATE_REQUEST,
        payload: { isLoadingUpdateDepositRate: true },
      });

      axios
        .post(`${RATE_ENDPOINT}UpdateDepositRate`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_DEPOSIT_RATE_SUCCESS,
            payload: { isLoadingUpdateDepositRate: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_DEPOSIT_RATE_FAILURE,
            payload: { isLoadingUpdateDepositRate: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};
