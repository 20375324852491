import {
  PageContainer,
  ProTable,
  ProFormText,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import moment from "moment";
import { Button, Divider, Drawer } from "antd";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { GetAllTransaction, ManualCallback } from "../../actions/transaction";
const TransactionPage = () => {
  const dispatch = useAppDispatch();
  const transaction_selector = useAppSelector((state) => state.transaction);
  const [searchParams, setSearchParams] = useState<
    Partial<API.TransactionListItem>
  >({});
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<API.TransactionListItem>();

  useEffect(() => {
    dispatch(GetAllTransaction({}));
  }, [dispatch]);

  const handleSearch = (params: any) => {
    setSearchParams(params);
    const newParams = {
      ...params,
      pageNumber: 1,
      pageSize: 10,
    };
    dispatch(GetAllTransaction(newParams));
  };

  const handleReset = () => {
    setSearchParams({});
    dispatch(GetAllTransaction({}));
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    const params = {
      ...searchParams, // 合并搜索条件
      pageNumber: page,
      pageSize,
    };
    dispatch(GetAllTransaction(params));
  };

  const handleManualCallback = (transactionId: any) => {
    dispatch(ManualCallback({ transactionId: transactionId }));
  };

  const columns: ProColumns<API.TransactionListItem>[] = [
    {
      title: "商户ID", // Column header
      dataIndex: "merchantId", // Key in the dataSource object
      key: "merchantId", // Unique key for this column
    },
    {
      title: "商户名字", // Column header
      dataIndex: "merchantName", // Key in the dataSource object
      key: "merchantName", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "总金额", // Column header
      dataIndex: "totalAmount", // Key in the dataSource object
      key: "totalAmount", // Unique key for this column
      render: (dom: any, entity: any) => {
        const totalAmount = `${entity?.currency} ${entity?.totalAmount}`;
        return totalAmount;
      },
    },
    {
      title: "创建日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },
    {
      title: "状态",
      dataIndex: "status",
      width: "auto",
      valueEnum: {
        0: {
          text: "待定",
          status: "Warning",
        },
        1: {
          text: "成功",
          status: "Success",
        },
        2: {
          text: "失败",
          status: "Error",
        },
        3: {
          text: "超时",
          status: "Error",
        },
      },
    },
    {
      title: "入金方式", // Column header
      dataIndex: "payModeName", // Key in the dataSource object
      key: "payModeName", // Unique key for this column
      hideInSearch: true,
      render: (dom: any, entity: any) => {
        return (
          <>
            <p style={{ margin: "10px 0" }}>入金方式: {entity?.payModeName}</p>
            <p>银行: {entity?.bankName}</p>
          </>
        );
      },
    },
    {
      title: "商户参照", // Column header
      dataIndex: "merchantReference", // Key in the dataSource object
      key: "merchantReference", // Unique key for this column
    },
    {
      title: "交易ID", // Column header
      dataIndex: "transactionId", // Key in the dataSource object
      key: "transactionId", // Unique key for this column
    },
    // {
    //   title: "网址", // Column header
    //   hideInSearch: true,
    //   render: (dom: any, entity: any) => {
    //     return (
    //       <>
    //         <p style={{ margin: "10px 0" }}>跳转网址: {entity?.redirectURL}</p>
    //         <p>回调网址: {entity?.callbackURL}</p>
    //       </>
    //     );
    //   },
    // },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record: any) => {
        return (
          <>
            <div>
              <Button
                onClick={() => {
                  setShowDrawer(true);
                  setCurrentRow(record);
                }}
              >
                付款详情
              </Button>
            </div>
            <div style={{ marginTop: "10px" }}>
              <Button
                color="primary"
                variant="link"
                onClick={() => handleManualCallback(record?.transactionId)}
                loading={
                  record?.transactionId ===
                    transaction_selector?.currentCallbackTransactionId &&
                  transaction_selector?.isLoadingPostManualCallback
                    ? true
                    : false
                }
              >
                发送回调
              </Button>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <>
      <PageContainer>
        <ProTable<API.TransactionListItem, API.PageParams>
          headerTitle="交易记录列表"
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: 10,
            current: transaction_selector?.transactionList?.pageNumber,
            total: transaction_selector?.transactionList?.total,
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
          }}
          dataSource={transaction_selector?.transactionList?.data}
          loading={transaction_selector?.isLoadingGetAllTransaction}
          onSubmit={handleSearch}
          onReset={handleReset}
          search={{ labelWidth: "auto" }}
        />
        <Drawer
          placement="right"
          open={showDrawer}
          onClose={() => {
            setShowDrawer(false);
            setCurrentRow(undefined);
          }}
        >
          <div>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>商户参照</p>
            <p>{currentRow?.merchantReference}</p>
          </div>
          <Divider />
          <div>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>交易ID</p>
            <p>{currentRow?.transactionId}</p>
          </div>
          <Divider />
          <div>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>跳转网址</p>
            <p>{currentRow?.redirectURL}</p>
          </div>
          <Divider />
          <div>
            <p style={{ fontSize: "16px", marginBottom: "10px" }}>回调网址</p>
            <p>{currentRow?.callbackURL}</p>
          </div>
          <Divider />
        </Drawer>
      </PageContainer>
    </>
  );
};

export default TransactionPage;
