import {
  PageContainer,
  ProTable,
  ModalForm,
  ProFormText,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import moment from "moment";
import { MERCHANT_INFO_ROUTE } from "../../../constant";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  AddMerchant,
  GetAllMerchant,
  UpdateMerchant,
} from "../../../actions/user";
import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import UpdateForm from "./components/UpdateForm";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { ShowSnackbar } from "../../../actions/snackbar";
import { useNavigate } from "react-router-dom";
const Merchant = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user_selector = useAppSelector((state) => state.user);
  const [currentRow, setCurrentRow] = useState<API.MerchantListItem>();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [merchantCreationModalVisible, handleMerchantCreationModalVisible] =
    useState<boolean>(false);
  const [createForm] = Form.useForm();
  const [merchantCreationInfo, setMerchantCreationInfo] = useState({
    merchantId: "",
    apiKey: "",
    password: "",
  });
  const [searchParams, setSearchParams] = useState<
    Partial<API.MerchantAdminListItem>
  >({});

  useEffect(() => {
    dispatch(GetAllMerchant({}));
  }, [dispatch]);

  const handleSearch = (params: any) => {
    setSearchParams(params);
    const newParams = {
      ...params,
      pageNumber: 1,
      pageSize: 10,
    };
    dispatch(GetAllMerchant(newParams));
  };

  const handleReset = () => {
    setSearchParams({});
    dispatch(GetAllMerchant({}));
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    const params = {
      ...searchParams, // 合并搜索条件
      pageNumber: page,
      pageSize,
    };
    dispatch(GetAllMerchant(params));
  };

  const handleUpdateMerchant = async (data: API.MerchantListItem) => {
    const status = await dispatch(UpdateMerchant(data));
    return status;
  };

  const handleAddMerchant = async (data: any) => {
    const response: any = await dispatch(AddMerchant(data));
    if (response?.succeeded) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllMerchant({}));
      setMerchantCreationInfo({
        merchantId: response?.data?.id,
        apiKey: response?.data?.apiKey,
        password: response?.data?.password,
      });
      handleMerchantCreationModalVisible(true);
    }
  };

  const columns: ProColumns<API.MerchantListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "userId", // Key in the dataSource object
      key: "userId", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "商户名字", // Column header
      dataIndex: "merchantName", // Key in the dataSource object
      key: "merchantName", // Unique key for this column
    },
    {
      title: "API Key", // Column header
      dataIndex: "apiKey", // Key in the dataSource object
      key: "apiKey", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "当前状态",
      dataIndex: "isDelete",
      valueEnum: {
        false: {
          text: "活跃",
          status: "Success",
        },
        true: {
          text: "封锁",
          status: "Error",
        },
      },
    },
    // {
    //   title: "创建日期",
    //   dataIndex: "createdAt",
    //   valueType: "date",
    //   render: (dom, entity) => {
    //     const createdDate = moment(entity.createdAt).format(
    //       "YYYY-MM-DD HH:mm:ss"
    //     );
    //     return createdDate;
    //   },
    // },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => {
        return (
          <Button
            color="primary"
            variant="link"
            onClick={() => navigate(`${MERCHANT_INFO_ROUTE}/${record?.accessId}`)}
            // onClick={() => {
            //   handleUpdateModalVisible(true);
            //   setCurrentRow(record);
            // }}
          >
            查询
          </Button>
        );
      },
    },
  ];

  const handleCopy = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(ShowSnackbar("复制成功", "success"));
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <PageContainer>
      <ProTable<API.MerchantListItem, API.PageParams>
        headerTitle="商户列表"
        columns={columns}
        rowKey="id"
        pagination={{
          pageSize: 10,
          current: user_selector?.merchantList?.pageNumber,
          total: user_selector?.merchantList?.total,
          onChange: (page, pageSize) => {
            handlePaginationChange(page, pageSize);
          },
        }}
        dataSource={user_selector?.merchantList?.data}
        loading={user_selector?.isLoadingGetAllMerchant}
        onSubmit={handleSearch}
        onReset={handleReset}
        search={{ labelWidth: "auto" }}
        toolBarRender={() => [
          <Button
            type="primary"
            key="primary"
            onClick={() => {
              handleModalVisible(true);
            }}
          >
            <PlusOutlined />
            添加商户
          </Button>,
        ]}
      />
      <ModalForm
        modalProps={{
          maskClosable: false,
        }}
        loading={user_selector?.isLoadingAddMerchant}
        title="添加商户"
        visible={createModalVisible}
        onVisibleChange={handleModalVisible}
        width="400px"
        form={createForm}
        onFinish={async (value) => {
          await handleAddMerchant(value);
        }}
      >
        <>
          <ProFormText
            rules={[
              {
                required: true,
                message: "请输入商户名字",
              },
            ]}
            width="md"
            name="name"
            label="商户名字"
          />
          <ProFormText
            rules={[
              {
                required: true,
                message: "请输入商户邮箱",
              },
              {
                type: "email",
                message: "请输入正确邮箱",
              },
            ]}
            width="md"
            name="email"
            label="商户邮箱"
          />
        </>
      </ModalForm>
      <ModalForm
        modalProps={{
          maskClosable: false,
        }}
        submitter={false}
        title="新商户信息"
        visible={merchantCreationModalVisible}
        onVisibleChange={handleMerchantCreationModalVisible}
        width="600px"
      >
        <>
          <div style={{ margin: "15px 0px" }}>
            <Input
              readOnly
              value={merchantCreationInfo?.merchantId}
              addonBefore="商户ID"
              addonAfter={
                <span
                  onClick={() => handleCopy(merchantCreationInfo?.merchantId)}
                  style={{ cursor: "pointer" }}
                >
                  复制
                </span>
              }
            />
          </div>
          <div style={{ margin: "15px 0px" }}>
            <Input
              readOnly
              value={merchantCreationInfo?.apiKey}
              addonBefore="商户API Key"
              addonAfter={
                <span
                  onClick={() => handleCopy(merchantCreationInfo?.apiKey)}
                  style={{ cursor: "pointer" }}
                >
                  复制
                </span>
              }
            />
          </div>
          <div>
            <p style={{ color: "red", marginBottom: "10px" }}>
              请注意: 此商户密码只显示一次, 请确保在关闭此窗口前保存此商户密码
            </p>
            <Input
              readOnly
              value={merchantCreationInfo?.password}
              addonBefore="商户密码"
              addonAfter={
                <span
                  onClick={() => handleCopy(merchantCreationInfo?.password)}
                  style={{ cursor: "pointer" }}
                >
                  复制
                </span>
              }
            />
          </div>
        </>
      </ModalForm>
      <UpdateForm
        onSubmit={async (value) => {
          const success = await handleUpdateMerchant(value);
          if (success) {
            handleUpdateModalVisible(false);
            setCurrentRow(undefined);
            dispatch(GetAllMerchant({}));
          }
        }}
        updateMerchantLoading={user_selector?.isLoadingUpdateMerchant}
        onCancel={() => {
          handleUpdateModalVisible(false);
          setCurrentRow(undefined);
        }}
        onVisibleChange={handleUpdateModalVisible}
        updateModalVisible={updateModalVisible}
        values={currentRow || {}}
      />
    </PageContainer>
  );
};

export default Merchant;
