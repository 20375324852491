// src/routes.js
import {
  DASHBOARD_ROUTE,
  MERCHANT_ROUTE,
  TRANSACTION_ROUTE,
} from "./constant";
import {
  UserOutlined,
  DashboardOutlined,
  DollarOutlined,
} from "@ant-design/icons";
const CustomRoutePath = [
  {
    path: DASHBOARD_ROUTE,
    name: "主页",
    icon: <DashboardOutlined />,
  },
  {
    path: MERCHANT_ROUTE,
    name: "商户",
    icon: <UserOutlined />,
  },
  {
    path: TRANSACTION_ROUTE,
    name: "交易记录",
    icon: <DollarOutlined />,
  },
  // Add more routes as needed
];

export default CustomRoutePath;
