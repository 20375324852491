export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//User
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

//Bank
export const GET_BANK_LIST_REQUEST = "GET_BANK_LIST_REQUEST";
export const GET_BANK_LIST_SUCCESS = "GET_BANK_LIST_SUCCESS";
export const GET_BANK_LIST_FAILURE = "GET_BANK_LIST_FAILURE";

export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST";
export const UPDATE_BANK_SUCCESS = "UPDATE_BANK_SUCCESS";
export const UPDATE_BANK_FAILURE = "UPDATE_BANK_FAILURE";

export const ADD_BANK_REQUEST = "ADD_BANK_REQUEST";
export const ADD_BANK_SUCCESS = "ADD_BANK_SUCCESS";
export const ADD_BANK_FAILURE = "ADD_BANK_FAILURE";

//Account
export const GET_ALL_ACCOUNT_REQUEST = "GET_ALL_ACCOUNT_REQUEST";
export const GET_ALL_ACCOUNT_SUCCESS = "GET_ALL_ACCOUNT_SUCCESS";
export const GET_ALL_ACCOUNT_FAILURE = "GET_ALL_ACCOUNT_FAILURE";

export const ADD_ACCOUNT_REQUEST = "ADD_ACCOUNT_REQUEST";
export const ADD_ACCOUNT_SUCCESS = "ADD_ACCOUNT_SUCCESS";
export const ADD_ACCOUNT_FAILURE = "ADD_ACCOUNT_FAILURE";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";
export const UPDATE_ACCOUNT_FAILURE = "UPDATE_ACCOUNT_FAILURE";

export const GET_ACCOUNT_BY_ID_REQUEST = "GET_ACCOUNT_BY_ID_REQUEST";
export const GET_ACCOUNT_BY_ID_SUCCESS = "GET_ACCOUNT_BY_ID_SUCCESS";
export const GET_ACCOUNT_BY_ID_FAILURE = "GET_ACCOUNT_BY_ID_FAILURE";

//Merchant
export const GET_ALL_MERCHANT_REQUEST = "GET_ALL_MERCHANT_REQUEST";
export const GET_ALL_MERCHANT_SUCCESS = "GET_ALL_MERCHANT_SUCCESS";
export const GET_ALL_MERCHANT_FAILURE = "GET_ALL_MERCHANT_FAILURE";

export const UPDATE_MERCHANT_REQUEST = "UPDATE_MERCHANT_REQUEST";
export const UPDATE_MERCHANT_SUCCESS = "UPDATE_MERCHANT_SUCCESS";
export const UPDATE_MERCHANT_FAILURE = "UPDATE_MERCHANT_FAILURE";

export const ADD_MERCHANT_REQUEST = "ADD_MERCHANT_REQUEST";
export const ADD_MERCHANT_SUCCESS = "ADD_MERCHANT_SUCCESS";
export const ADD_MERCHANT_FAILURE = "ADD_MERCHANT_FAILURE";

export const GET_MERCHANT_INFO_REQUEST = "GET_MERCHANT_INFO_REQUEST";
export const GET_MERCHANT_INFO_SUCCESS = "GET_MERCHANT_INFO_SUCCESS";
export const GET_MERCHANT_INFO_FAILURE = "GET_MERCHANT_INFO_FAILURE";

//Merchant Admin
export const GET_ALL_MERCHANT_ADMIN_REQUEST = "GET_ALL_MERCHANT_ADMIN_REQUEST";
export const GET_ALL_MERCHANT_ADMIN_SUCCESS = "GET_ALL_MERCHANT_ADMIN_SUCCESS";
export const GET_ALL_MERCHANT_ADMIN_FAILURE = "GET_ALL_MERCHANT_ADMIN_FAILURE";

export const UPDATE_MERCHANT_ADMIN_REQUEST = "UPDATE_MERCHANT_ADMIN_REQUEST";
export const UPDATE_MERCHANT_ADMIN_SUCCESS = "UPDATE_MERCHANT_ADMIN_SUCCESS";
export const UPDATE_MERCHANT_ADMIN_FAILURE = "UPDATE_MERCHANT_ADMIN_FAILURE";

export const ADD_MERCHANT_ADMIN_REQUEST = "ADD_MERCHANT_ADMIN_REQUEST";
export const ADD_MERCHANT_ADMIN_SUCCESS = "ADD_MERCHANT_ADMIN_SUCCESS";
export const ADD_MERCHANT_ADMIN_FAILURE = "ADD_MERCHANT_ADMIN_FAILURE";

//Transaction
export const GET_ALL_TRANSACTION_REQUEST = "GET_ALL_TRANSACTION_REQUEST";
export const GET_ALL_TRANSACTION_SUCCESS = "GET_ALL_TRANSACTION_SUCCESS";
export const GET_ALL_TRANSACTION_FAILURE = "GET_ALL_TRANSACTION_FAILURE";

//Manual Callback
export const POST_MANUAL_CALLBACK_REQUEST = "POST_MANUAL_CALLBACK_REQUEST";
export const POST_MANUAL_CALLBACK_SUCCESS = "POST_MANUAL_CALLBACK_SUCCESS";
export const POST_MANUAL_CALLBACK_FAILURE = "POST_MANUAL_CALLBACK_FAILURE";

//Deposit Rate
export const GET_ALL_DEPOSIT_RATE_REQUEST = "GET_ALL_DEPOSIT_RATE_REQUEST";
export const GET_ALL_DEPOSIT_RATE_SUCCESS = "GET_ALL_DEPOSIT_RATE_SUCCESS";
export const GET_ALL_DEPOSIT_RATE_FAILURE = "GET_ALL_DEPOSIT_RATE_FAILURE";

export const UPDATE_DEPOSIT_RATE_REQUEST = "UPDATE_DEPOSIT_RATE_REQUEST";
export const UPDATE_DEPOSIT_RATE_SUCCESS = "UPDATE_DEPOSIT_RATE_SUCCESS";
export const UPDATE_DEPOSIT_RATE_FAILURE = "UPDATE_DEPOSIT_RATE_FAILURE";
