import React, { useEffect } from "react";
import {
  ModalForm,
  ProFormText,
  ProForm,
  ProFormSwitch,
} from "@ant-design/pro-components";
import { Form, Input } from "antd";

export type FormValueType = Partial<API.MerchantListItem>;

export type UpdateFormProps = {
  onCancel: any;
  //   onCancel: (flag?: boolean, formVals?: FormValueType) => void;
  onSubmit: (values: FormValueType) => Promise<void>;
  updateModalVisible: boolean;
  onVisibleChange: any;
  values: Partial<API.MerchantListItem>;
  updateMerchantLoading: boolean;
};

const UpdateForm: React.FC<UpdateFormProps> = (props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.values);
  }, [props.values, form]);

  return (
    <ModalForm<API.MerchantListItem>
      width={700}
      title="更新用户"
      visible={props.updateModalVisible}
      onVisibleChange={props.onVisibleChange}
      modalProps={{
        onCancel: props.onCancel,
      }}
      onFinish={props.onSubmit}
      form={form}
      initialValues={{ ...props.values }}
      loading={props.updateMerchantLoading}
    >
      <ProForm.Group>
        <ProFormText name="userId" label="ID" readonly />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormText
          name="merchantName"
          label="商户名字"
          rules={[
            {
              required: true,
              message: "请输入商户名字",
            },
          ]}
        />
      </ProForm.Group>
      <ProForm.Group>
        <ProFormSwitch
          name="isDelete"
          label="封锁状态"
          checkedChildren="Yes"
          unCheckedChildren="No"
        />
      </ProForm.Group>
    </ModalForm>
  );
};

export default UpdateForm;
