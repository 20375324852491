import {
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_FAILURE,
  POST_LOGIN_SUCCESS,
  GET_ALL_MERCHANT_REQUEST,
  GET_ALL_MERCHANT_SUCCESS,
  GET_ALL_MERCHANT_FAILURE,
  UPDATE_MERCHANT_REQUEST,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT_FAILURE,
  ADD_MERCHANT_REQUEST,
  ADD_MERCHANT_SUCCESS,
  ADD_MERCHANT_FAILURE,
  GET_ALL_MERCHANT_ADMIN_REQUEST,
  GET_ALL_MERCHANT_ADMIN_SUCCESS,
  GET_ALL_MERCHANT_ADMIN_FAILURE,
  UPDATE_MERCHANT_ADMIN_REQUEST,
  UPDATE_MERCHANT_ADMIN_SUCCESS,
  UPDATE_MERCHANT_ADMIN_FAILURE,
  ADD_MERCHANT_ADMIN_REQUEST,
  ADD_MERCHANT_ADMIN_SUCCESS,
  ADD_MERCHANT_ADMIN_FAILURE,
  GET_MERCHANT_INFO_REQUEST,
  GET_MERCHANT_INFO_SUCCESS,
  GET_MERCHANT_INFO_FAILURE,
} from "./actions";
import axios from "../AxiosConfig";
import { DASHBOARD_ROUTE, USER_ROUTE } from "../constant";
import { ShowSnackbar } from "./snackbar";

const USER_ENDPOINT = "/api/User/";
export const GetUserInfo = () => {
  return (dispatch: any) => {
    if (localStorage.getItem("currentUser")) {
      const curentLoginUser = JSON.parse(
        localStorage.getItem("currentUser") || ""
      );
      dispatch({
        type: GET_USER_INFO_REQUEST,
        payload: { isLoadingGetUserInfo: true },
      });

      axios
        .get(`${USER_ENDPOINT}${curentLoginUser?.userId}`)
        .then((res) => {
          dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload: { isLoadingGetUserInfo: false, userInfo: res?.data?.data },
          });
        })
        .catch((err) => {
          dispatch({
            type: GET_USER_INFO_FAILURE,
            payload: { isLoadingGetUserInfo: false },
          });
        });
    }
  };
};

export const Login = (loginData: any, navigate: any) => {
  return (dispatch: any) => {
    dispatch({
      type: POST_LOGIN_REQUEST,
      payload: { isLoadingLogin: true },
    });
    axios
      .post(`${USER_ENDPOINT}Login`, loginData)
      .then((res) => {
        const responseData = res?.data?.data;
        const sessionData = {
          userId: responseData?.userId,
          accessToken: responseData?.accessToken,
        };
        localStorage.setItem("currentUser", JSON.stringify(sessionData));
        dispatch({
          type: POST_LOGIN_SUCCESS,
          payload: { isLoadingLogin: false },
        });
        dispatch(ShowSnackbar("登入成功", "success"));
        navigate(DASHBOARD_ROUTE);
      })
      .catch((err) => {
        dispatch(ShowSnackbar("登入失败", "error"));
        dispatch({
          type: POST_LOGIN_FAILURE,
          payload: { isLoadingLogin: false },
        });
      });
  };
};

export const GetAllMerchant = (params: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ALL_MERCHANT_REQUEST,
      payload: { isLoadingGetAllMerchant: true },
    });

    axios
      .get(`${USER_ENDPOINT}MerchantList`, { params })
      .then((res) => {
        dispatch({
          type: GET_ALL_MERCHANT_SUCCESS,
          payload: { isLoadingGetAllMerchant: false, merchantList: res?.data },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_MERCHANT_FAILURE,
          payload: { isLoadingGetAllMerchant: false },
        });
      });
  };
};

export const AddMerchant = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_MERCHANT_REQUEST,
        payload: { isLoadingAddMerchant: true },
      });

      axios
        .post(`${USER_ENDPOINT}CreateMerchant`, data)
        .then((res) => {
          dispatch({
            type: ADD_MERCHANT_SUCCESS,
            payload: { isLoadingAddMerchant: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(res?.data); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_MERCHANT_FAILURE,
            payload: { isLoadingAddMerchant: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          const resData: any = {
            succeeded: false,
          };
          resolve(resData); // Return false on failure
        });
    });
  };
};

export const UpdateMerchant = (data: API.MerchantListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_MERCHANT_REQUEST,
        payload: { isLoadingUpdateMerchant: true },
      });

      axios
        .post(`${USER_ENDPOINT}UpdateMerchant`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_MERCHANT_SUCCESS,
            payload: { isLoadingUpdateMerchant: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_MERCHANT_FAILURE,
            payload: { isLoadingUpdateMerchant: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const GetMerchantInfo = (accessId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_MERCHANT_INFO_REQUEST,
      payload: { isLoadingGetMerchantInfo: true },
    });

    const params = {
      accessId: accessId,
    };

    axios
      .get(`${USER_ENDPOINT}GetMerchantInfo`, { params })
      .then((res) => {
        dispatch({
          type: GET_MERCHANT_INFO_SUCCESS,
          payload: {
            isLoadingGetMerchantInfo: false,
            merchantInfo: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_MERCHANT_INFO_FAILURE,
          payload: { isLoadingGetMerchantInfo: false },
        });

        dispatch(ShowSnackbar("无法获取商户信息", "error"));
      });
  };
};
//Merchant Admin

export const GetAllMerchantAdmin = (params: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ALL_MERCHANT_ADMIN_REQUEST,
      payload: { isLoadingGetAllMerchantAdmin: true },
    });
    axios
      .get(`${USER_ENDPOINT}MerchantAdminList`, { params })
      .then((res) => {
        dispatch({
          type: GET_ALL_MERCHANT_ADMIN_SUCCESS,
          payload: {
            isLoadingGetAllMerchantAdmin: false,
            merchantAdminList: res?.data,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_MERCHANT_ADMIN_FAILURE,
          payload: { isLoadingGetAllMerchantAdmin: false },
        });
      });
  };
};

export const UpdateMerchantAdmin = (data: API.MerchantAdminListItem) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: UPDATE_MERCHANT_ADMIN_REQUEST,
        payload: { isLoadingUpdateMerchantAdmin: true },
      });

      axios
        .post(`${USER_ENDPOINT}UpdateMerchantAdmin`, data)
        .then((res) => {
          dispatch({
            type: UPDATE_MERCHANT_ADMIN_SUCCESS,
            payload: { isLoadingUpdateMerchantAdmin: false },
          });
          dispatch(ShowSnackbar("更新成功", "success"));
          resolve(true); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_MERCHANT_ADMIN_FAILURE,
            payload: { isLoadingUpdateMerchantAdmin: false },
          });
          dispatch(ShowSnackbar("更新失败", "error"));
          resolve(false); // Return false on failure
        });
    });
  };
};

export const AddMerchantAdmin = (data: any) => {
  return (dispatch: any) => {
    return new Promise<boolean>((resolve, reject) => {
      dispatch({
        type: ADD_MERCHANT_ADMIN_REQUEST,
        payload: { isLoadingAddMerchantAdmin: true },
      });

      axios
        .post(`${USER_ENDPOINT}CreateMerchantAdmin`, data)
        .then((res) => {
          dispatch({
            type: ADD_MERCHANT_ADMIN_SUCCESS,
            payload: { isLoadingAddMerchantAdmin: false },
          });
          dispatch(ShowSnackbar("添加成功", "success"));
          resolve(res?.data); // Return true on success
        })
        .catch((err) => {
          dispatch({
            type: ADD_MERCHANT_ADMIN_FAILURE,
            payload: { isLoadingAddMerchantAdmin: false },
          });
          dispatch(ShowSnackbar("添加失败", "error"));
          const resData: any = {
            succeeded: false,
          };
          resolve(resData); // Return false on failure
        });
    });
  };
};
