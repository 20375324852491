import { PageContainer } from "@ant-design/pro-components";
import { Card, Row, Col, Divider, Button, Input, Empty } from "antd";
import styles from "./merchant-info.module.scss";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useParams } from "react-router-dom";
import { GetMerchantInfo, UpdateMerchant } from "../../../actions/user";
import moment from "moment";
import MerchantUpdateForm from "./components/MerchantUpdateForm";
import MerchantAdmin from "./components/merchant-admin/merchant-admin-list";
import { GetAllDepositRate, UpdateDepositRate } from "../../../actions/rate";
import { ShowSnackbar } from "../../../actions/snackbar";

interface DepositRateItem {
  id: number;
  payModeId: number;
  payModeName: string;
  percent: any;
}

const MerchantInfo = () => {
  const dispatch = useAppDispatch();
  const user_selector = useAppSelector((state) => state.user);
  const rate_selector = useAppSelector((state) => state.rate);
  const { id } = useParams();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);

  const [depositRateData, setDepositRateData] = useState<DepositRateItem[]>([]);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, item: any) => {
    const reg = /^\d+(\.\d+)?$/;
    const percent = e.target.value;
    if (reg.test(percent)) {
      const updatedData = depositRateData?.map((x: any) => {
        if (x.id === item.id) {
          return { ...x, percent: parseFloat(percent) }; // Update percent value
        }
        return x; // Keep the rest unchanged
      });

      setDepositRateData(updatedData);
    }
  };

  useEffect(() => {
    dispatch(GetMerchantInfo(id));
    dispatch(GetAllDepositRate({ accessId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    if (rate_selector?.depositRateData?.data) {
      setDepositRateData(rate_selector?.depositRateData?.data);
    }
  }, [rate_selector?.depositRateData?.data]);

  const handleUpdateMerchant = async (data: API.MerchantListItem) => {
    const status = await dispatch(UpdateMerchant(data));
    return status;
  };

  const handleUpdateDepositRate = async (id: any) => {
    const findItem = depositRateData?.find((x: any) => x.id === id);
    console.log(findItem);
    if (findItem) {
      const status = await dispatch(UpdateDepositRate(findItem, id));
      if (status) {
        window.location.reload();
      }
    } else {
      dispatch(ShowSnackbar("保存失败, 请重新尝试", "error"));
    }
  };
  return (
    <>
      <PageContainer>
        <Row gutter={[24, 16]}>
          <Col xs={24} sm={24} md={12}>
            <Card
              title="商户信息"
              bordered={false}
              loading={user_selector?.isLoadingGetMerchantInfo}
            >
              <div>
                <p className={styles.merchantInfoTitle}>商户ID</p>
                <p className={styles.merchantInfoValue}>
                  {user_selector?.merchantInfo?.data?.userId}
                </p>
              </div>
              <Divider style={{ margin: "16px 0" }} />
              <div>
                <p className={styles.merchantInfoTitle}>商户名字</p>
                <p className={styles.merchantInfoValue}>
                  {user_selector?.merchantInfo?.data?.merchantName}
                </p>
              </div>
              <Divider style={{ margin: "16px 0" }} />
              <div>
                <p className={styles.merchantInfoTitle}>API密钥</p>
                <p className={styles.merchantInfoValue}>
                  {user_selector?.merchantInfo?.data?.apiKey}
                </p>
              </div>
              <Divider style={{ margin: "16px 0" }} />
              <div>
                <p className={styles.merchantInfoTitle}>注册日期</p>
                <p className={styles.merchantInfoValue}>
                  {moment(user_selector?.merchantInfo?.data?.createdAt).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                </p>
              </div>
              <Divider style={{ margin: "16px 0" }} />
              <div>
                <p className={styles.merchantInfoTitle}>状态</p>
                <p
                  className={styles.merchantInfoValue}
                  style={
                    user_selector?.merchantInfo?.data?.isDelete
                      ? { color: "red" }
                      : { color: "green" }
                  }
                >
                  {user_selector?.merchantInfo?.data?.isDelete
                    ? "封锁"
                    : "活跃"}
                </p>
              </div>
              <div style={{ textAlign: "end" }}>
                <Button
                  color="primary"
                  variant="link"
                  onClick={() => {
                    handleUpdateModalVisible(true);
                  }}
                >
                  更改
                </Button>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Card
              title="入金抽成费率"
              bordered={false}
              loading={rate_selector?.isLoadingGetAllDepositRate}
            >
              {rate_selector?.depositRateData?.data?.length > 0 ? (
                <>
                  {rate_selector?.depositRateData?.data?.map(
                    (item: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Row gutter={[24, 16]}>
                            <Col xs={10} sm={10} md={10}>
                              <p className={styles.merchantDepositRatePayMode}>
                                {item?.payModeName}
                              </p>
                            </Col>
                            <Col xs={10} sm={10} md={10}>
                              <Input
                                onChange={(e) => handleChange(e, item)}
                                style={{ width: "100%" }}
                                defaultValue={item?.percent}
                                type="number"
                              />
                            </Col>
                            <Col xs={4} sm={4} md={4}>
                              <Button
                                style={{ width: "100%", textAlign: "center" }}
                                onClick={() =>
                                  handleUpdateDepositRate(item?.id)
                                }
                              >
                                保存
                              </Button>
                            </Col>
                          </Row>
                          <Divider />
                        </React.Fragment>
                      );
                    }
                  )}
                </>
              ) : (
                <Empty />
              )}
            </Card>
          </Col>
        </Row>
      </PageContainer>
      <MerchantAdmin
        accessId={id}
        merchantId={user_selector?.merchantInfo?.data?.userId}
      />
      <MerchantUpdateForm
        onSubmit={async (value) => {
          const success = await handleUpdateMerchant(value);
          if (success) {
            handleUpdateModalVisible(false);
            dispatch(GetMerchantInfo(id));
          }
        }}
        updateMerchantLoading={user_selector?.isLoadingUpdateMerchant}
        onCancel={() => {
          handleUpdateModalVisible(false);
        }}
        onVisibleChange={handleUpdateModalVisible}
        updateModalVisible={updateModalVisible}
        values={user_selector?.merchantInfo?.data || {}}
      />
    </>
  );
};

export default MerchantInfo;
