import {
  ProTable,
  ModalForm,
  ProFormText,
  PageContainer,
} from "@ant-design/pro-components";
import type { ActionType, ProColumns } from "@ant-design/pro-components";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  AddMerchantAdmin,
  GetAllMerchantAdmin,
  UpdateMerchantAdmin,
} from "../../../../../actions/user";
import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import MerchantAdminUpdateForm from "./MerchantAdminUpdateForm";
import { PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { ShowSnackbar } from "../../../../../actions/snackbar";
interface Props {
  accessId: any;
  merchantId: any;
}
const MerchantAdmin: React.FC<Props> = ({ accessId, merchantId }) => {
  const dispatch = useAppDispatch();
  const user_selector = useAppSelector((state) => state.user);
  const [currentRow, setCurrentRow] = useState<API.MerchantAdminListItem>();
  const [updateModalVisible, handleUpdateModalVisible] =
    useState<boolean>(false);
  const [createModalVisible, handleModalVisible] = useState<boolean>(false);
  const [
    merchantAdminCreationModalVisible,
    handleMerchantAdminCreationModalVisible,
  ] = useState<boolean>(false);
  const [createForm] = Form.useForm();
  const [merchantAdminCreationInfo, setMerchantAdminCreationInfo] = useState({
    email: "",
    password: "",
  });

  const [searchParams, setSearchParams] = useState<
    Partial<API.MerchantAdminListItem>
  >({});

  useEffect(() => {
    dispatch(GetAllMerchantAdmin({ accessId: accessId }));
  }, [dispatch, accessId]);

  const handleSearch = (params: any) => {
    setSearchParams(params);
    const newParams = {
      ...params,
      pageNumber: 1,
      pageSize: 10,
      accessId: accessId,
    };
    dispatch(GetAllMerchantAdmin(newParams));
  };

  const handleReset = () => {
    setSearchParams({});
    dispatch(GetAllMerchantAdmin({ accessId: accessId }));
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    const params = {
      ...searchParams, // 合并搜索条件
      pageNumber: page,
      pageSize: pageSize,
      accessId: accessId,
    };
    dispatch(GetAllMerchantAdmin(params));
  };

  const handleUpdateMerchantAdmin = async (data: API.MerchantAdminListItem) => {
    const status = await dispatch(UpdateMerchantAdmin(data));
    return status;
  };

  const handleAddMerchantAdmin = async (data: any) => {
    const response: any = await dispatch(AddMerchantAdmin(data));
    if (response?.succeeded) {
      createForm.resetFields();
      handleModalVisible(false);
      dispatch(GetAllMerchantAdmin({ accessId: accessId }));
      setMerchantAdminCreationInfo({
        email: response?.data?.email,
        password: response?.data?.password,
      });
      handleMerchantAdminCreationModalVisible(true);
    }
  };

  const columns: ProColumns<API.MerchantAdminListItem>[] = [
    {
      title: "ID", // Column header
      dataIndex: "merchantAdminId", // Key in the dataSource object
      key: "userId", // Unique key for this column
      hideInSearch: true,
    },
    {
      title: "商户管理员名字", // Column header
      dataIndex: "name", // Key in the dataSource object
      key: "name", // Unique key for this column
    },
    {
      title: "商户管理员邮箱", // Column header
      dataIndex: "email", // Key in the dataSource object
      key: "email", // Unique key for this column
    },
    {
      title: "当前状态",
      dataIndex: "isDelete",
      valueEnum: {
        false: {
          text: "活跃",
          status: "Success",
        },
        true: {
          text: "封锁",
          status: "Error",
        },
      },
    },
    {
      title: "创建日期",
      dataIndex: "createdAt",
      valueType: "date",
      render: (dom, entity) => {
        const createdDate = moment(entity.createdAt).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        return createdDate;
      },
    },
    {
      title: "选项",
      dataIndex: "option",
      valueType: "option",
      hideInDescriptions: true,
      render: (_, record) => {
        return (
          <Button
            color="primary"
            variant="link"
            onClick={() => {
              handleUpdateModalVisible(true);
              setCurrentRow(record);
            }}
          >
            更改
          </Button>
        );
      },
    },
  ];

  const handleCopy = async (text: any) => {
    try {
      await navigator.clipboard.writeText(text);
      dispatch(ShowSnackbar("复制成功", "success"));
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };
  return (
    <>
      <PageContainer header={{title:"商户管理员"}}>
        <ProTable<API.MerchantAdminListItem, API.PageParams>
          columns={columns}
          rowKey="id"
          pagination={{
            pageSize: 10,
            current: user_selector?.merchantAdminList?.pageNumber,
            total: user_selector?.merchantAdminList?.total,
            onChange: (page, pageSize) => {
              handlePaginationChange(page, pageSize);
            },
          }}
          dataSource={user_selector?.merchantAdminList?.data}
          loading={user_selector?.isLoadingGetAllMerchantAdmin}
          onSubmit={handleSearch}
          onReset={handleReset}
          search={{ labelWidth: "auto" }}
          toolBarRender={() => [
            <Button
              type="primary"
              key="primary"
              onClick={() => {
                handleModalVisible(true);
              }}
            >
              <PlusOutlined />
              添加商户管理员
            </Button>,
          ]}
        />
        <ModalForm
          modalProps={{
            maskClosable: false,
          }}
          loading={user_selector?.isLoadingAddMerchantAdmin}
          title="添加商户管理员"
          visible={createModalVisible}
          onVisibleChange={handleModalVisible}
          width="400px"
          form={createForm}
          onFinish={async (value) => {
            await handleAddMerchantAdmin(value);
          }}
        >
          <>
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入商户管理员名字",
                },
              ]}
              width="md"
              name="name"
              label="商户管理员名字"
            />
            <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入商户管理员邮箱",
                },
                {
                  type: "email",
                  message: "请输入正确邮箱",
                },
              ]}
              width="md"
              name="email"
              label="商户管理员邮箱"
            />
            <ProFormText
              hidden
              initialValue={merchantId}
              name="merchantIdToAssign"
            />
            {/* <ProFormText
              rules={[
                {
                  required: true,
                  message: "请输入商户ID",
                },
              ]}
              width="md"
              name="merchantIdToAssign"
              label="商户ID"
            /> */}
          </>
        </ModalForm>
        <ModalForm
          modalProps={{
            maskClosable: false,
          }}
          submitter={false}
          title="新商户管理员信息"
          visible={merchantAdminCreationModalVisible}
          onVisibleChange={handleMerchantAdminCreationModalVisible}
          width="600px"
        >
          <>
            <div style={{ margin: "15px 0px" }}>
              <Input
                readOnly
                value={merchantAdminCreationInfo?.email}
                addonBefore="商户管理员邮箱"
                addonAfter={
                  <span
                    onClick={() => handleCopy(merchantAdminCreationInfo?.email)}
                    style={{ cursor: "pointer" }}
                  >
                    复制
                  </span>
                }
              />
            </div>
            <div>
              <p style={{ color: "red", marginBottom: "10px" }}>
                请注意: 此商户管理员密码只显示一次,
                请确保在关闭此窗口前保存此商户管理员密码
              </p>
              <Input
                readOnly
                value={merchantAdminCreationInfo?.password}
                addonBefore="商户密码"
                addonAfter={
                  <span
                    onClick={() =>
                      handleCopy(merchantAdminCreationInfo?.password)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    复制
                  </span>
                }
              />
            </div>
          </>
        </ModalForm>
        <MerchantAdminUpdateForm
          onSubmit={async (value) => {
            const success = await handleUpdateMerchantAdmin(value);
            if (success) {
              handleUpdateModalVisible(false);
              setCurrentRow(undefined);
              dispatch(GetAllMerchantAdmin({ accessId: accessId }));
            }
          }}
          updateMerchantAdminLoading={
            user_selector?.isLoadingUpdateMerchantAdmin
          }
          onCancel={() => {
            handleUpdateModalVisible(false);
            setCurrentRow(undefined);
          }}
          onVisibleChange={handleUpdateModalVisible}
          updateModalVisible={updateModalVisible}
          values={currentRow || {}}
        />
      </PageContainer>
    </>
  );
};

export default MerchantAdmin;
