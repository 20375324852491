import { PageContainer } from "@ant-design/pro-components";

const Dashboard = () => {
  return (
    <>
      <p>This is Dashboard Page</p>
    </>
  );
};

export default Dashboard;
