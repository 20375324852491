import {
  GET_ALL_MERCHANT_REQUEST,
  GET_ALL_MERCHANT_SUCCESS,
  GET_ALL_MERCHANT_FAILURE,
  GET_USER_INFO_FAILURE,
  GET_USER_INFO_REQUEST,
  GET_USER_INFO_SUCCESS,
  POST_LOGIN_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  UPDATE_MERCHANT_REQUEST,
  UPDATE_MERCHANT_SUCCESS,
  UPDATE_MERCHANT_FAILURE,
  ADD_MERCHANT_REQUEST,
  ADD_MERCHANT_SUCCESS,
  ADD_MERCHANT_FAILURE,
  GET_ALL_MERCHANT_ADMIN_REQUEST,
  GET_ALL_MERCHANT_ADMIN_SUCCESS,
  GET_ALL_MERCHANT_ADMIN_FAILURE,
  ADD_MERCHANT_ADMIN_REQUEST,
  ADD_MERCHANT_ADMIN_SUCCESS,
  ADD_MERCHANT_ADMIN_FAILURE,
  UPDATE_MERCHANT_ADMIN_REQUEST,
  UPDATE_MERCHANT_ADMIN_SUCCESS,
  UPDATE_MERCHANT_ADMIN_FAILURE,
  GET_MERCHANT_INFO_REQUEST,
  GET_MERCHANT_INFO_SUCCESS,
  GET_MERCHANT_INFO_FAILURE,
} from "../actions/actions";

const INITIAL_STATE = {
  isLoadingGetUserInfo: false,
  userInfo: null,
  isLoadingLogin: false,
  merchantList: null,
  isLoadingGetAllMerchant: false,
  isLoadingAddMerchant: false,
  isLoadingUpdateMerchant: false,
  isLoadingGetAllMerchantAdmin: false,
  isLoadingAddMerchantAdmin: false,
  isLoadingUpdateMerchantAdmin: false,
  merchantAdminList: null,
  isLoadingGetMerchantInfo: false,
  merchantInfo: null,
};

const UserReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_USER_INFO_REQUEST:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
      };

    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
        userInfo: action.payload.userInfo,
      };
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        isLoadingGetUserInfo: action.payload.isLoadingGetUserInfo,
      };
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case GET_ALL_MERCHANT_REQUEST:
      return {
        ...state,
        isLoadingGetAllMerchant: action.payload.isLoadingGetAllMerchant,
      };
    case GET_ALL_MERCHANT_SUCCESS:
      return {
        ...state,
        isLoadingGetAllMerchant: action.payload.isLoadingGetAllMerchant,
        merchantList: action.payload.merchantList,
      };
    case GET_ALL_MERCHANT_FAILURE:
      return {
        ...state,
        isLoadingGetAllMerchant: action.payload.isLoadingGetAllMerchant,
      };
    case ADD_MERCHANT_REQUEST:
      return {
        ...state,
        isLoadingAddMerchant: action.payload.isLoadingAddMerchant,
      };
    case ADD_MERCHANT_SUCCESS:
      return {
        ...state,
        isLoadingAddMerchant: action.payload.isLoadingAddMerchant,
      };
    case ADD_MERCHANT_FAILURE:
      return {
        ...state,
        isLoadingAddMerchant: action.payload.isLoadingAddMerchant,
      };
    case UPDATE_MERCHANT_REQUEST:
      return {
        ...state,
        isLoadingUpdateMerchant: action.payload.isLoadingUpdateMerchant,
      };
    case UPDATE_MERCHANT_SUCCESS:
      return {
        ...state,
        isLoadingUpdateMerchant: action.payload.isLoadingUpdateMerchant,
      };
    case UPDATE_MERCHANT_FAILURE:
      return {
        ...state,
        isLoadingUpdateMerchant: action.payload.isLoadingUpdateMerchant,
      };

    case GET_ALL_MERCHANT_ADMIN_REQUEST:
      return {
        ...state,
        isLoadingGetAllMerchantAdmin:
          action.payload.isLoadingGetAllMerchantAdmin,
      };
    case GET_ALL_MERCHANT_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingGetAllMerchantAdmin:
          action.payload.isLoadingGetAllMerchantAdmin,
        merchantAdminList: action.payload.merchantAdminList,
      };
    case GET_ALL_MERCHANT_ADMIN_FAILURE:
      return {
        ...state,
        isLoadingGetAllMerchantAdmin:
          action.payload.isLoadingGetAllMerchantAdmin,
      };
    case ADD_MERCHANT_ADMIN_REQUEST:
      return {
        ...state,
        isLoadingAddMerchantAdmin: action.payload.isLoadingAddMerchantAdmin,
      };
    case ADD_MERCHANT_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingAddMerchantAdmin: action.payload.isLoadingAddMerchantAdmin,
      };
    case ADD_MERCHANT_ADMIN_FAILURE:
      return {
        ...state,
        isLoadingAddMerchantAdmin: action.payload.isLoadingAddMerchantAdmin,
      };

    case UPDATE_MERCHANT_ADMIN_REQUEST:
      return {
        ...state,
        isLoadingUpdateMerchantAdmin:
          action.payload.isLoadingUpdateMerchantAdmin,
      };
    case UPDATE_MERCHANT_ADMIN_SUCCESS:
      return {
        ...state,
        isLoadingUpdateMerchantAdmin:
          action.payload.isLoadingUpdateMerchantAdmin,
      };
    case UPDATE_MERCHANT_ADMIN_FAILURE:
      return {
        ...state,
        isLoadingUpdateMerchantAdmin:
          action.payload.isLoadingUpdateMerchantAdmin,
      };
    case GET_MERCHANT_INFO_REQUEST:
      return {
        ...state,
        isLoadingGetMerchantInfo: action.payload.isLoadingGetMerchantInfo,
      };
    case GET_MERCHANT_INFO_SUCCESS:
      return {
        ...state,
        isLoadingGetMerchantInfo: action.payload.isLoadingGetMerchantInfo,
        merchantInfo: action.payload.merchantInfo,
      };
    case GET_MERCHANT_INFO_FAILURE:
      return {
        ...state,
        isLoadingGetMerchantInfo: action.payload.isLoadingGetMerchantInfo,
      };
    default:
      return state;
  }
};

export default UserReducer;
